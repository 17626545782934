.App {
  text-align: center;
}

.App > * {
  cursor: default;
}

.main-body {
  height: 100vh;
}

.container {
  cursor: default;
  min-height: 100vh;
  max-height: 100vh;
  display: grid;
  grid-template-columns: minmax(40px, auto) auto minmax(40px, auto);
  grid-template-rows: minmax(40px, auto) auto minmax(40px, auto);
}

.transition-grid {
  position: absolute;
  z-index: 9;
  min-height: 100vh;
  max-height: 100vh;
  display: grid;
  transition: grid-template-columns 2s ease-in, grid-template-rows 2s ease-in;
}

.transition-grid.invisible {
  display: none;
}

.transition-grid.visible {
  display: grid;
}

.transition-grid.no-transition {
  transition: 0s;
}

.loading {
  position: absolute;
  z-index: 8;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  display: grid;
  place-content: center;
}

.loading.invisible-loading {
  display: none;
}

.loading-card {
  display: grid;
  place-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
}

.loading-card.invisible-loading-card {
  display: none;
}

.loading-text{
  text-align: center;
}

.loading-text:after {
  position: absolute;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  text-align: left;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 1600ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.15em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.15em;    
  }
}

.middle-cell {
  grid-column-start: 2;
  grid-row-start: 2;
  height: auto;
  display: flex;
  position: relative;
}

.about-cell {
  grid-column-start: 1;
  grid-row-start: 3;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  font-size: 16px;
  cursor: default;
  z-index: 8;
}

.about-text {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: default;
  background-color: white;
  padding-left: 2rem;
  padding-right: 2rem;
}

.about-text.visible-about-text {
  display: grid;
  place-content: center;
}

.info-pane {
  display: grid;
  flex-grow: 1;
}

.card-container {
  height: auto;
  display: grid;
  place-content: end center;
}

.info-card {
  cursor: pointer;
  min-width: 300px;
  border: solid black 1px;
  margin: 2rem;
  display: grid;
  place-content: center;
}

.info-card:hover {
  background-color: black;
}

.info-card:hover > *{
  color: white;
}

.info {
  padding-bottom: 0rem;
}

.vogue-link {
  font-size: 12px;
  margin: 0;
  padding: 0.5rem 0.5rem 1rem 1rem;
  text-align: left;
  font-style: italic;
}

.vogue-link-mobile-container {
  display: none;
}

img {
  max-height: calc(100vh - 80px);
  max-width: 50%;
  object-fit: cover;
  border-left: solid black 1px;
}

.error-message {
  display: grid;
  width: 100%;
  height: 100%;
  place-content: center;
}

.left-gutter {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 4;
  border-right: solid black 1px;
}

.right-gutter {
  grid-column-start: 3;
  grid-row-start: 1;
  grid-row-end: 4;
  border-left: solid black 1px;
}

.right-gutter.closed {
  border-left: solid black 0px;
}

.top-gutter {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  border-bottom: solid black 1px;
}

.bottom-gutter {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3;
  border-top: solid black 1px;
}

.bottom-gutter.closed{
  border-top: solid black 0px;
}

.cell-one {
  grid-column-start: 1;
  grid-row-start: 1;
  background: white;
}

.cell-two {
  grid-column-start: 2;
  grid-row-start: 1;
  background: white;
}

.cell-three {
  grid-column-start: 3;
  grid-row-start: 1;
  background: white;
}

.cell-four {
  grid-column-start: 1;
  grid-row-start: 2;
  background: white;
}

.cell-five {
  grid-column-start: 3;
  grid-row-start: 2;
  background: white;
}

.cell-six {
  grid-column-start: 1;
  grid-row-start: 3;
  background: white;
}

.cell-seven {
  grid-column-start: 2;
  grid-row-start: 3;
  background: white;
}

.cell-eight {
  grid-column-start: 3;
  grid-row-start: 3;
  background: white;
}

// Mobile
@media only screen and (max-width: 449px) {
body {
  font-size: 14px;
}

.main-body {
  height: 100svh;
}

.container {
  min-height: 100svh;
  max-height: 100svh;
  display: grid;
  grid-template-columns: minmax(0px, auto) max-content minmax(0px, auto);
  grid-template-rows: minmax(20px, auto) auto minmax(20px, auto);
}

.container.isSafari {
  border-top: solid #dbdbdb 0.5px;
}

.transition-grid {
  min-height: 100svh;
  max-height: 100svh;
}

.transition-grid.isSafari {
  border-top: solid #dbdbdb 0.5px;
}

.loading {
  min-height: 100svh;
  max-height: 100svh;
}

.loading-card {
  height: 100svh;
}

.info-pane {
  grid-row-start: 2;
  display: block;
  margin: 0.5rem;
}

.info-pane.mobile-click {
  margin: 0;
  padding: 0.5rem;
  background-color: black;
}

.info-card-link {
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
}

.card-container {
  position: relative;
  grid-row-start: 2;
  height: 100%;
  display: grid;
  place-content: center;
}

.info-card {
  max-width: calc(100vw - 90px);
  border: none;
  margin: 0rem;
  min-width: 250px;
}

.info-card.hidden {
  z-index: 1;
  background-color: black;
  color: black;
}

.info-card:hover {
  background-color: white;
}

.info-card:hover > *{
  color: black;
}

.info {
  padding: 0rem;
}

.vogue-link {
  display: none;
}

.vogue-link-mobile-container {
  display: none;
  z-index: 0;
}

.vogue-link-mobile-container.is-visible {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;
  display: grid;
  place-content: center;
  background-color: black;
  color: white;
}

.vogue-link-mobile {
  color: white;
  font-style: italic;
}

.instructions {
  display: none
}

.middle-cell {
  grid-column-start: 2;
  grid-row-start: 2;
  width: fit-content;
  display: grid;
  grid-template-rows: max-content auto;
}

img {
  min-width: 100%;
  max-width: calc(100vw - 45px);
  max-height: calc(100svh - 200px);
  object-fit: contain;
  border: none;
  border-bottom: solid black 1px;
}

li {
  overflow: hidden;
  text-overflow: ellipsis;
}

}

// Mid-Size Screen
@media only screen and (min-width: 450px) and (max-width: 850px) {
  body {
    font-size: 14px;
  }
  
  .main-body {
    height: 100svh;
  }
  
  .container {
    min-height: 100svh;
    max-height: 100svh;
    display: grid;
    grid-template-columns: minmax(0px, auto) max-content minmax(0px, auto);
    grid-template-rows: minmax(20px, auto) auto minmax(20px, auto);
  }
  
  .transition-grid {
    min-height: 100svh;
    max-height: 100svh;
  }
  
  .loading {
    min-height: 100svh;
    max-height: 100svh;
  }
  
  .loading-card {
    height: 100svh;
  }
  
  .info-pane {
    grid-row-start: 2;
    display: block;
    margin: 0.5rem;
  }
  
  .info-pane:hover {
    margin: 0;
    padding: 0.5rem;
    background-color: black;
  }
  
  .card-container {
    position: relative;
    grid-row-start: 2;
    height: 100%;
    display: grid;
    place-content: center;
  }
  
  .info-card {
    min-width: 34%;
    max-width: calc(100vw - 80px);
    border: none;
    margin: 0rem;
    z-index: 6;
  }
  
  .info-card:hover {
    z-index: 1;
    background-color: black;
    color: black;
  }
  
  .info-card:hover > *{
    color: black;
  }
  
  .info {
    padding: 0rem;
  }
  
  .vogue-link {
    display: none;
  }
  
  .vogue-link-mobile-container {
    display: grid;
    place-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 5;
    color: white;
  }
  
  .vogue-link-mobile {
    color: white;
    font-style: italic;
  }
  
  .instructions {
    display: none
  }
  
  .middle-cell {
    grid-column-start: 2;
    grid-row-start: 2;
    width: fit-content;
    display: grid;
    grid-template-rows: max-content auto;
  }
  
  img {
    min-width: 100%;
    max-width: calc(100vw - 60px);
    max-height: calc(100svh - 200px);
    object-fit: contain;
    border: none;
    border-bottom: solid black 1px;
  }
  
  }