* {
  box-sizing: border-box;
}

*:focus {
  box-shadow: none;
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  line-height: 1.5;
  letter-spacing: 0.02rem;
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  color: black;
}

ul {
  list-style: none;
  margin: 0;
  padding: 1rem;
}

button {
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: left;
}

p {
  text-align: center;
}

@media only screen and (max-width: 700px) {
  body {
    font-size: 14px;
  }
  }